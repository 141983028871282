<template>
  <div class="friend_bg_view" @scroll="onScrollAction">
    <div class="item_view" v-for="item in dataList" :key="item.id">
      <div class="item_time_tx">{{ item.time }}</div>
      <div class="user_item">
        <img class="user_img" :src="require('@/assets/xbj_avatar.jpg')" alt="" />
        <div class="normal_text" style="min-height: 44px; margin-left: 10px; max-width: calc(100% - 54px);">
          <div style="font-weight: bold">
            <span>{{ item.userName }}</span>
            <span style="color: #409EFF;">@必要</span>
          </div>
          <div style="color: #666">
            <span v-for="(txItem, idx) in item.contentTextList" :key="`key_${idx}`">
              <span v-if="txItem.type == 'text'" v-html="txItem.text"></span>
              <a target="_blank" style="color:#66b1ff;" :href="txItem.text" v-if="txItem.type == 'url'">{{ txItem.text }}</a>
            </span>
          </div>
        </div>
      </div>
      <!-- 链接模块 -->
      <template v-if="item.linkAssetsList.length">
        <div class="link_item_view" v-for="linkItem in item.linkAssetsList" :key="linkItem.url" @click="openLinkAction(linkItem.link_url)">
          <img :src="linkItem.url || require('@/assets/icon_empty.png')" alt="" />
          <div class="normal_text">
            <div style="line-height: 20px; margin-top: 4px">
              {{ linkItem.link_title }}
            </div>
            <div style="font-size: 12px; color: #666; padding-bottom: 10px">
              {{ linkItem.link_desc }}
            </div>
          </div>
        </div>
      </template>
      <!-- 视频模块 -->
      <template v-if="item.videoAssetsList.length">
        <div class="video_item_view" v-for="videoItem in item.videoAssetsList" :key="videoItem.url" @click="openVideoAction(videoItem.url)">
          <video :src="videoItem.url" width="100%" style="pointer-events: none; border-radius: 4px"></video>
          <img :src="require('@/assets/icon_video_play.png')" alt="" class="icon_play" />
        </div>
        <el-dialog title="视频播放" destroy-on-close :visible="!!showVideoUrl" @close="showVideoUrl = ''">
          <video :src="showVideoUrl" width="100%" style="max-height: calc(100vh / 2)" controls autoPlay></video>
        </el-dialog>
      </template>
      <!-- 图片模块 -->
      <template v-if="item.imageAssetsList.length">
        <div class="img_bg_view" :style="item.imageAssetsList.length == 4 ? 'max-width: 190px' : ''">
          <el-image fit="contain" :preview-src-list="[imgItem.url]" :src="imgItem.url" v-for="imgItem in item.imageAssetsList" :key="imgItem.url"></el-image>
        </div>
      </template>
      <!-- 评论回复模块 -->
      <template v-if="item.commentList && item.commentList.length">
        <div class="user_item" style="margin-left: 54px;" v-for="commonItem in item.commentList" :key="commonItem.id">
          <img class="user_img" style="width: 30px;height: 30px;" :src="require('@/assets/xbj_avatar.jpg')" alt="" />
          <div class="normal_text common_normal_text">
            <div class="common_user_name">
              <span>{{ selectUserInfo.xbjNickName }}</span>
              <span style="color:#409EFF">@必要</span>
            </div>
            <div style="color: #666;">
              <span v-for="(txItem, idx) in commonItem.contentTextList" :key="`key_${idx}`">
                <span v-if="txItem.type == 'text'" v-html="txItem.text"></span>
                <a target="_blank" style="color:#66b1ff;" :href="txItem.text" v-if="txItem.type == 'url'">{{ txItem.text }}</a>
              </span>
            </div>
          </div>
          <div class="common_time_tx">{{ commonItem.time }}</div>
        </div>
      </template>
    </div>
    <div class="bottom_tx" v-loading="showLoading" v-show="dataList.length">{{ isLastPage ? '-没有更多了-' : '' }}</div>
    <el-empty v-if="dataList.length == 0" description="暂无数据" :image="require('@/assets/icon_empty.png')"></el-empty>
  </div>
</template>

<script>
import API from "@/common/axios";
import { textMessageToHtml } from '../../contentView/common/utils';
export default {
  props: ["selectUserInfo"],
  data() {
    return {
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      showVideoUrl: '',
      isLastPage: false,
      showLoading: false
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      API({
        // url: "https://mock.apifox.com/m1/4186304-0-default/getFriendInfo",
        url: "/moment/list.do",
        data: {
          xbjId: this.selectUserInfo.xbjId,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        method: "POST",
      }).then((res) => {
        if (res.success == 1) {
          res.data.forEach((item) => {
            item.linkAssetsList = item.assetsList ? item.assetsList.filter((item) => item.type == "link") : [];
            item.videoAssetsList = item.assetsList ? item.assetsList.filter((item) => item.type == "video") : [];
            item.imageAssetsList = item.assetsList ? item.assetsList.filter((item) => item.type == "image") : [];
            item.contentTextList = this.contentTextToHtml(item.contentText);
            item.commentList && item.commentList.forEach(cItem => {
              cItem.contentTextList = this.contentTextToHtml(cItem.contentText);
            });
          });
          this.dataList.push(...res.data);
          if (res.data.length < this.pageSize) {
            this.isLastPage = true;
          }
        } else {
          this.$message.error(res.errMsg);
        }
      }).finally(_ => this.showLoading = false);
    },
    // 将文本转换成html
    contentTextToHtml(text) {
      const urlPattern = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
      let lastIndex = 0;
      let resultArray = [];
      let match;
      while ((match = urlPattern.exec(text)) !== null) {
        let nonMatchedPart = text.slice(lastIndex, match.index);
        if (nonMatchedPart) {
          resultArray.push({ type: 'text', text: nonMatchedPart });
        }
        resultArray.push({ type: 'url', text: match[0] });
        lastIndex = match.index + match[0].length;
      }
      if (lastIndex < text.length) {
        resultArray.push({ type: 'text', text: text.slice(lastIndex) });
      }
      resultArray.forEach(item => {
        if (item.type == 'text') {
          let escapedText = item.text.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\//g, '&#x2F;');
          item.text = textMessageToHtml(escapedText);
        }
      })
      return resultArray;
    },
    // 滑动监听
    onScrollAction(e) {
      if (this.showLoading || this.isLastPage) {
        return;
      }
      let container = e.currentTarget;
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight;
      const scrollHeight = container.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        this.showLoading = true;
        this.pageIndex += 1;
        this.getData();
      }
    },
    // 打开链接
    openLinkAction(url) {
      window.open(url, "_blank");
    },
    // 打开视频
    openVideoAction(url) {
      this.showVideoUrl = url;
    }
  },
};
</script>

<style lang="scss" scoped>
.friend_bg_view {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fafafa;

  .item_view {
    font-size: 14px;
    color: #333;
    padding-bottom: 10px;
    border-bottom: 1px solid #999;

    .item_time_tx {
      line-height: 30px;
      padding-left: 15px;
    }

    .user_item {
      display: flex;
      flex-direction: row;
      padding: 10px 15px 0;
      position: relative;

      .user_img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }

      .common_user_name {
        font-weight: bold;
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .common_time_tx {
        position: absolute;
        right: 8px;
        top: 10px;
        font-size: 12px;
        line-height: 20px;
        transform: scale(0.8);
      }
    }

    .normal_text {
      word-break: break-all;
      text-align: justify;
    }

    .common_normal_text {
      min-height: 30px;
      margin-left: 10px;
      font-size: 12px;
    }

    .link_item_view {
      display: flex;
      min-height: 50px;
      margin: 10px 10px 0 69px;
      padding: 0 10px 0px 0;
      background-color: white;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      cursor: pointer;

      img {
        width: 42px;
        height: 42px;
        margin: 4px 10px 0 4px;
      }
    }

    .video_item_view {
      margin-left: 69px;
      margin-top: 10px;
      // max-height: 300px;
      position: relative;
      cursor: pointer;
      font-size: 0;
      max-width: 300px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      .icon_play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
      }
    }

    .img_bg_view {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 59px;
      max-width: 300px;

      /deep/ .el-image {
        width: 80px;
        height: 80px;
        margin: 10px 0 0 10px;
        border-radius: 4px;
      }
    }
  }

  .bottom_tx {
    min-height: 30px;
    width: 100%;
    color: #666;
    text-align: center;
    line-height: 30px;
    font-size: 12px;

    /deep/ .el-loading-spinner .circular {
      width: 30px;
      height: 30px;
    }
  }
}
</style>